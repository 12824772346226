.menu-bottom {
    display: flex;
    flex-direction: row;
    @include phones {
        // flex-direction: column;
				// align-items: center;
				display: none;
    }
}
ul.menu-bottom__list {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    margin-right: 20px;
    @include phones {
        margin-top: 10px;
        align-items: center;
        margin-right: 0px;
    }
}

a.menu-bottom__link {
    font-size: 0.95rem;
    font-weight: 500;
    color: $black;
    white-space: nowrap;
    &:hover {
        color:$red;
    }
}
