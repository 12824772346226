.breadcrumbs {
    ul {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
				margin: 20px 0;
				flex-wrap: wrap;
        li {
            font-family: 'Roboto';
            font-size: 14px;
            color:$red;
            &:after {
                content:"»";
                display: inline-block;
                margin: 0 5px;
                color:$gray;
            }
            &:last-child {
                &:after {
                    display: none;
                }
            }
            a {
                color:$gray;
                &:hover {
                    text-decoration: underline;
                    text-decoration-style: dotted;
                }
            }
        }
    }
}