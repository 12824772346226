.menu-left {
    ul {
        li:last-child {
            border-bottom: none;
        }
        li {
            display: flex;
            justify-content: space-between;
            align-items: center;
            border-bottom: 1px solid $bggray;
            &:after {
                content: "";
                display: block;
                width: 20px;
                height: 20px;
                @include sprite($arrow-right-small-red);
                margin-right: 20px;
            }
        }
        background-color: #fff;
        padding: 10px;
        li a:hover {
            color:$red;
        }
    }
    a {
        display: block;
        color:$black;
        font-size: 1rem;
        padding: 10px 15px;
        font-family: "roboto", sans-serif;
        font-style: normal;
        font-weight: 400;
        width: 100%;
        margin-right: 20px;
        &:hover {
            text-decoration: none;
            color:$red;
        }
        @include desktop {
            padding: 20px 10px;
        }
        @include laptop {
            padding: 15px 5px;
        }
    }
}

