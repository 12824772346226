@font-face {
    font-family: 'Roboto';
    src: local('Roboto Thin'), local('Roboto-Thin'), url('/assets/fonts/roboto/Robotothin.woff2') format('woff2'), url('/assets/fonts/roboto/Robotothin.woff') format('woff'), url('/assets/fonts/roboto/Robotothin.ttf') format('truetype');
    font-weight: 100;
    font-style: normal;
}
@font-face {
    font-family: 'Roboto';
    src: local('Roboto Thin Italic'), local('Roboto-ThinItalic'), url('/assets/fonts/roboto/Robotothinitalic.woff2') format('woff2'), url('/assets/fonts/roboto/Robotothinitalic.woff') format('woff'), url('/assets/fonts/roboto/Robotothinitalic.ttf') format('truetype');
    font-weight: 100;
    font-style: italic;
}
@font-face {
    font-family: 'Roboto';
    src: local('Roboto Light'), local('Roboto-Light'), url('/assets/fonts/roboto/Robotolight.woff2') format('woff2'), url('/assets/fonts/roboto/Robotolight.woff') format('woff'), url('/assets/fonts/roboto/Robotolight.ttf') format('truetype');
    font-weight: 300;
    font-style: normal;
}
@font-face {
    font-family: 'Roboto';
    src: local('Roboto Light Italic'), local('Roboto-LightItalic'), url('/assets/fonts/roboto/Robotolightitalic.woff2') format('woff2'), url('/assets/fonts/roboto/Robotolightitalic.woff') format('woff'), url('/assets/fonts/roboto/Robotolightitalic.ttf') format('truetype');
    font-weight: 300;
    font-style: italic;
}
@font-face {
    font-family: 'Roboto';
    src: local('RobotoRegular'), local('Roboto-Regular'), url('/assets/fonts/roboto/Roboto.woff2') format('woff2'), url('/assets/fonts/roboto/Roboto.woff') format('woff'), url('/assets/fonts/roboto/Roboto.ttf') format('truetype');
    font-weight: 400;
    font-style: normal;
}
@font-face {
    font-family: 'Roboto';
    src: local('Roboto Italic'), local('Roboto-Italic'), url('/assets/fonts/roboto/Robotoitalic.woff2') format('woff2'), url('/assets/fonts/roboto/Robotoitalic.woff') format('woff'), url('/assets/fonts/roboto/Robotoitalic.ttf') format('truetype');
    font-weight: 400;
    font-style: italic;
}
@font-face {
    font-family: 'Roboto';
    src: local('Roboto Medium'), local('Roboto-Medium'), url('/assets/fonts/roboto/Robotomedium.woff2') format('woff2'), url('/assets/fonts/roboto/Robotomedium.woff') format('woff'), url('/assets/fonts/roboto/Robotomedium.ttf') format('truetype');
    font-weight: 500;
    font-style: normal;
}
@font-face {
    font-family: 'Roboto';
    src: local('Roboto Medium Italic'), local('Roboto-MediumItalic'), url('/assets/fonts/roboto/Robotomediumitalic.woff2') format('woff2'), url('/assets/fonts/roboto/Robotomediumitalic.woff') format('woff'), url('/assets/fonts/roboto/Robotomediumitalic.ttf') format('truetype');
    font-weight: 500;
    font-style: italic;
}
@font-face {
    font-family: 'Roboto';
    src: local('Roboto Bold'), local('Roboto-Bold'), url('/assets/fonts/roboto/Robotobold.woff2') format('woff2'), url('/assets/fonts/roboto/Robotobold.woff') format('woff'), url('/assets/fonts/roboto/Robotobold.ttf') format('truetype');
    font-weight: 700;
    font-style: normal;
}
@font-face {
    font-family: 'Roboto';
    src: local('Roboto Bold Italic'), local('Roboto-BoldItalic'), url('/assets/fonts/roboto/Robotobolditalic.woff2') format('woff2'), url('/assets/fonts/roboto/Robotobolditalic.woff') format('woff'), url('/assets/fonts/roboto/Robotobolditalic.ttf') format('truetype');
    font-weight: 700;
    font-style: italic;
}
@font-face {
    font-family: 'Roboto';
    src: local('Roboto Black'), local('Roboto-Black'), url('/assets/fonts/roboto/Robotoblack.woff2') format('woff2'), url('/assets/fonts/roboto/Robotoblack.woff') format('woff'), url('/assets/fonts/roboto/Robotoblack.ttf') format('truetype');
    font-weight: 900;
    font-style: normal;
}
@font-face {
    font-family: 'Roboto';
    src: local('Roboto Black Italic'), local('Roboto-BlackItalic'), url('/assets/fonts/roboto/Robotoblackitalic.woff2') format('woff2'), url('/assets/fonts/roboto/Robotoblackitalic.woff') format('woff'), url('/assets/fonts/roboto/Robotoblackitalic.ttf') format('truetype');
    font-weight: 900;
    font-style: italic;
}

@font-face {
    font-family: "MerriweatherRegular";
    src: url("/assets/fonts/Merriweather/MerriweatherRegular.eot");
    src: url("/assets/fonts/Merriweather/MerriweatherRegular.eot?#iefix")format("embedded-opentype"),
    url("/assets/fonts/Merriweather/MerriweatherRegular.woff") format("woff"),
    url("/assets/fonts/Merriweather/MerriweatherRegular.ttf") format("truetype");
    font-style: normal;
    font-weight: normal;
}

@font-face {
    font-family: "MerriweatherBold";
    src: url("/assets/fonts/Merriweather/MerriweatherBold.eot");
    src: url("/assets/fonts/Merriweather/MerriweatherBold.eot?#iefix")format("embedded-opentype"),
    url("/assets/fonts/Merriweather/MerriweatherBold.woff") format("woff"),
    url("/assets/fonts/Merriweather/MerriweatherBold.ttf") format("truetype");
    font-style: normal;
    font-weight: normal;
}

@font-face {
    font-family: "MerriweatherItalic";
    src: url("/assets/fonts/Merriweather/MerriweatherItalic.eot");
    src: url("/assets/fonts/Merriweather/MerriweatherItalic.eot?#iefix")format("embedded-opentype"),
    url("/assets/fonts/Merriweather/MerriweatherItalic.woff") format("woff"),
    url("/assets/fonts/Merriweather/MerriweatherItalic.ttf") format("truetype");
    font-style: normal;
    font-weight: normal;
}

@font-face {
    font-family: "MerriweatherLight";
    src: url("/assets/fonts/Merriweather/MerriweatherLight.eot");
    src: url("/assets/fonts/Merriweather/MerriweatherLight.eot?#iefix")format("embedded-opentype"),
    url("/assets/fonts/Merriweather/MerriweatherLight.woff") format("woff"),
    url("/assets/fonts/Merriweather/MerriweatherLight.ttf") format("truetype");
    font-style: normal;
    font-weight: normal;
}

@font-face {
    font-family: "MerriweatherBoldItalic";
    src: url("/assets/fonts/Merriweather/MerriweatherBoldItalic.eot");
    src: url("/assets/fonts/Merriweather/MerriweatherBoldItalic.eot?#iefix")format("embedded-opentype"),
    url("/assets/fonts/Merriweather/MerriweatherBoldItalic.woff") format("woff"),
    url("/assets/fonts/Merriweather/MerriweatherBoldItalic.ttf") format("truetype");
    font-style: normal;
    font-weight: normal;
}

@font-face {
    font-family: "MerriweatherLightItalic";
    src: url("/assets/fonts/Merriweather/MerriweatherLightItalic.eot");
    src: url("/assets/fonts/Merriweather/MerriweatherLightItalic.eot?#iefix")format("embedded-opentype"),
    url("/assets/fonts/Merriweather/MerriweatherLightItalic.woff") format("woff"),
    url("/assets/fonts/Merriweather/MerriweatherLightItalic.ttf") format("truetype");
    font-style: normal;
    font-weight: normal;
}