.kamins-list-line {
	display: flex;
	flex-direction: column;
	justify-content: stretch;
	flex-wrap: wrap;
	margin-bottom: 40px;
	.kamin-item {
			display: flex;
			flex-direction: row;
			justify-content: space-between;
			background-color: #fff;
			margin: 10px;
			@include phones {
				flex-direction: column;
			}
			// max-width: 360px;
			&:hover {
					z-index: 3;
					box-shadow: 0px 0px 20px 0px $gray;
			}
			a.kamin-item__img {
					// height: 230px;
					// text-align: center;
					min-width: 300px;
					overflow: hidden;
					max-height: 300px;
					@include phones {
						min-width: auto;
					}
					img {
							
							// height: auto;
							max-height: 100%;
							max-width: 100% !important;
							margin: auto;
							display: block;
					}
					&:hover {
							opacity: 0.7;
					}
			}
			.kamin-item__text {
					padding: 0 10px;
					width: 100%;
					span {
							display: block;
							font-family: "Roboto";
								font-weight: bold;
							font-size: 0.95rem;
							color:$black;
							margin-top: 10px;
					}
					p {
							margin-top: 10px;
							font-size: 0.9rem;
							color:$gray;
					}
					@include laptop {
						display:none;
					}
					@include phones {
						display:block;
					}
			}
			.kamin-item__right {
				display:flex;
				flex-direction:column;
				justify-content:space-between;
				min-width:250px;
				border-left:1px solid $bggray;
				background-color: #fff;
				@include laptop {
					min-width:200px;
				}
				.kamin-item__price {
					display: flex;
					flex-direction:column;
					justify-content: center;
					align-items:center;
					padding: 10px;
					@include phones {
						flex-direction:row;
						justify-content: space-between;
					}
					span {
						font-family: "Roboto";
						font-weight: bold;
							font-size: 1.3rem;
							color:$red;
					}
					strong {
							font-size: 0.9rem;
							font-weight: normal;
							color:$black;
					}
			}
			.kamin-item__footer {
					display: flex;
					justify-content: flex-start;
					border-top:1px solid $bggray;
					margin-top: 40px;
					.kamin-item__comparison {
							display: flex;
							justify-content: center;
							align-items: center;
							width: 60px;
							height: 60px;
							border-right:1px solid $bggray;
							&:after {
									content: "";
									display: block;
									@include sprite($untitled);
							}
							&:hover,
							&:active {
									&:after {
											@include sprite($untitled-a);
									}
							}
							// &:hover {
							//     box-shadow: inset 0px 0px 0px 1px $red;
							// }
					}
					button.kamin-item__heart,
					a.kamin-item__heart {
							display: flex;
							justify-content: center;
							align-items: center;
							width: 60px !important;
							height: 60px;
							border: 0;
							background-color: #fff;
							border-right:1px solid $bggray;
							cursor: pointer;
							&:after {
									content: "";
									display: block;
									@include sprite($heart);
							}
							&:hover,
							&:active {
									&:after {
											@include sprite($heart-a);
									}
							}
							// &:hover {
							//     box-shadow: inset 0px 0px 0px 1px $red;
							// }
					}
					button.kamin-item__add-cart,
					a.kamin-item__add-cart {
							color:$red;
							text-align: center;
							padding: 18px 0px;
							white-space: nowrap;
							text-decoration: none;
							background-color: #fff;
							border: none;
							cursor: pointer;
							&:hover {
									color:#fff;
									background-color: $red;
							}
					}
			}
			}
	}
}

// .js-related-products {
// 	.owl-nav {
// 			top: -50px;
// 			right: 10px;
// 	@include laptop {
// 			top:-40px;
// 			}
// 	}
// }