.acsesuary {
  margin-top: 30px;
  .nav-link {
    margin: 0;
    padding: 1rem;
  }
  .owl-stage,
  .owl-item {
    display: flex;
    align-items: stretch;
  }
}

.nav-pills .nav-link,
.nav-pills .show > .nav-link {
  font-family: "Roboto";
  font-weight: 400;
  color: $black;
  background-color: #fff;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-top: 1px solid $bggray;
  &:after {
    content: "";
    display: inline-block;
    @include sprite($arrow-right-small-gray);
  }
}
.nav-pills .nav-link.active,
.nav-pills .show > .nav-link,
.nav-pills .nav-link:hover {
  font-weight: 500;
  color: $red;
  background-color: #fff;
  &:after {
    content: "";
    display: inline-block;
    @include sprite($arrow-right-small-red);
  }
}
.nav-pills .nav-link:hover {
  font-weight: 400;
}

.acsesuary__list {
  display: flex;
  justify-content: stretch;
}

.acsesuary__item {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  border-left: 1px solid $bggray;
  padding: 0 0 11px 0;
	width: 100%;
  &:hover {
    text-decoration: none;
    box-shadow: 0 0px 10px 0px rgba(0, 0, 0, 0.1);
    z-index: 10;
  }
  img {
		margin: 0px 20px auto;
  }
  .acsesuary__img {
    display: flex;
    justify-content: center;
		align-items: center;
		min-height: 200px;
    img {
			// margin: 0px 20px auto;
			margin: auto 20px;
    }
  }
  .acsesuary__text {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
		align-items: center;
		min-height: 100px;
    p {
      font-family: "Roboto";
      font-weight: bold;
      font-size: 0.95rem;
      line-height: 1.1rem;
      color: black;
			padding: 0 5px;
			margin: 10px 0;
      text-align: center;
    }
    span {
      font-family: "Roboto";
      font-weight: bold;
      font-size: 1.05rem;
			color: $red;
			span {
				font-weight: normal;
			}
    }
  }
}
