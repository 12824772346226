.menu-top {
    position: relative;
    margin: 10px 0;
    display: flex;
    flex-direction: row;
		justify-content: space-between;
		flex-wrap: wrap;
    @include phones {
        display: none;
    }
}
ul.top-menu__list {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
}

a.top-menu__link {
    font-size: 0.8rem;
    color: $black;
		margin-right: 20px;
		white-space: nowrap;
		@include laptop {
			margin-right: 7px;
		}
    &:hover {
        text-decoration-style: dotted;
    }
    @include phones {
        margin-right: 5px;
    }
}





a.top-menu__link-dropdown {
    display: block;
    padding: 0 10px;
    padding-bottom: 10px;
}

.top-menu__list-second {
    display: none;
    position: absolute;
    top: 29px;
    background-color: #fff;
    z-index: 1;
    padding: 5px 10px;
    box-shadow: 0 3px 3px 0 $gray;
}

.top-menu__item-dropdown:hover  .top-menu__list-second {
    display: block;
}