.nav-link {
	font-family: "Roboto";
	font-weight: bold;
    padding: 1rem 0;
    margin:0 1rem;
    color:$black;
}
.nav-tabs {
    @include phones {
        display: flex;
        flex-direction: column;
		}
		li {
			list-style: none;
		}
}
.nav-tabs .nav-item {
    margin-bottom: 0px;
}
.nav-tabs .nav-link {
    border-bottom-width:2px;
}
.nav-tabs .nav-link.active, .nav-tabs .nav-item.show .nav-link {
    color: $red;
    background-color: #fff;
    border-color: #fff #fff $red #fff;
    border-bottom-width:2px;
}

.nav-tabs .nav-link:hover, .nav-tabs .nav-link:focus {
    color:$red;
    border-color: #fff #fff $red #fff;
    border-bottom-width:2px;
}

.owl-nav {
    position: absolute;
    display: flex;
    right: 0;
		top: -60px;
		@include desktop {
			top:-53px;
		}
    @include laptop {
        top:-47px;
		}
		// @include phones {
		// 	top:-47px;
		// }
}

.carousel-product-prev,
.carousel-product-next {
    width: 60px;
    height: 60px;
    border-left: 1px solid $bggray;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #fff;
    &:after {
        content: "";
        display: block;
    }
    &:hover {
        background-color: rgba($color: #000000, $alpha: 0.1);
		}
		@include desktop {
			height:53px;
		}
    @include laptop {
        height: 47px;
        border: 1px solid $bggray;
		}
		// @include phones {
		// 	top:47px;
		// }
}

.carousel-product-prev {
    &:after {
        @include sprite($arrow-left);
    }
}
.carousel-product-next {
    &:after {
        @include sprite($arrow-right);
    }
}