.filter {
  margin-bottom: 20px;
  @include laptop {
    padding-top: 40px;
  }
  .close-title {
    display: none;
    @include laptop {
      display: block;
      position: absolute;
      top: 10px;
      left: 10px;
      color: #7e7e7e;
      font-size: 16px;
      line-height: 22px;
    }
  }
  .close {
    display: none;
    cursor: pointer;
    @include laptop {
      display: block;
      position: absolute;
      top: 0;
      right: 0;
      width: 40px;
      height: 40px;
    }
    &:hover {
      color: #fff !important;
      background-color: $red;
      opacity: 0.8;
    }
    &:active {
      opacity: 1;
    }
  }
  .show-more {
		display: block;
		width: 100%;
		height: 100%;
    border: none;
    background: transparent;
    padding: 15px 10px;
		color: rgba($red, 0.7);
		background-color: #fff;
		cursor: pointer;
		text-align: left;
    &:before {
      content: "+";
      display: inline-block;
      margin-right: 5px;
    }
    &:hover {
      color: $red;
    }
    &:active {
      color: rgba($red, 0.7);
    }
  }
  .card {
    border: 0;
    border-bottom: 1px solid $bggray;
    &:nth-child(n + 10) {
      display: none;
    }
    &:first-child {
      border-top: 1px solid $bggray;
    }
    .card-header {
      // display: flex;
      // justify-content: space-between;
      // align-items: center;
      background-color: #fff;
      border-bottom: 0;

      padding: 0;
      button {
        display: flex;
        justify-content: space-between;
        align-items: center;
        font-family: "Roboto";
				// font-weight: bold;
				font-weight: normal;
        font-size: 0.95rem;
        color: $black;
        text-decoration: none;
        width: 100%;
        padding: 10px;
        white-space: pre-wrap;
        &:hover {
          color: $red;
        }
        &:after {
          content: "";
          display: inline-block;
          @include sprite($arrow-down);
        }
      }
    }
    .card-body {
      padding-right: 40px;
      padding-left: 35px;
      .checkbox {
        display: none;
      }
      .checkbox-custom {
        position: relative;
        width: 17px;
        height: 17px;
        border: 1px solid $black;
        margin-right: 10px;
      }
      .checkbox-custom,
      .label {
        display: inline-block;
        vertical-align: middle;
        cursor: pointer;
        font-size: 0.8rem;
        color: #7e7e7e;
        &:hover {
          color: $red;
        }
      }
      .checkbox:checked + .checkbox-custom::before {
        content: "";
        display: block;
        position: absolute;
        top: 3px;
        right: 3px;
        bottom: 3px;
        left: 3px;
        background: $red;
			}
			label {
				white-space: nowrap;
				span {
					white-space: pre-wrap;
				}
			}
		}
		#collapse_31, #collapse_29, #collapse_49, #collapse_60, #collapse_61, #collapse_68, #collapse_132 {
			.card-body {
				display: flex;
				flex-wrap: wrap;
				label {
					width: 50%;
				}
			}
		}
	}
	&.open {
		.card {
			&:nth-child(n + 10) {
				display: block;
			}
		}
	}
  .filter__buttons {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #fff;
    border-top: 1px solid $bggray;
    a {
      width: 50%;
      padding: 10px 0;
      border: 1px solid transparent;
    }
    a.button {
      color: $gray;
      &:hover {
        color: #fff;
        background-color: $red;
        border: 1px solid $red;
        border-right-color: $bggray;
      }
    }
    a.button-red {
      color: #fff;
      background-color: $red;
      &:hover {
        color: $red;
        background-color: #fff;
        border: 1px solid $red;
      }
    }
  }

  // #js-ui-slider-price {
  .js-ui-slider {
    width: 100%;
    height: 2px;
    margin: 10px 0px 30px;
    background-color: $bggray;
    border: none;
    cursor: pointer;
  }
  .input-date {
    display: flex;
    justify-content: space-between;
    margin-top: -10px;
    margin-left: -10px;
    margin-right: -10px;
  }
  .date {
    // display: none;
    display: inline-block;
    border: 1px solid rgba($red, 0.1);
    padding: 0 5px;
    // margin-top: 10px;
    width: 80px;
    font-size: 12px;
    &-first {
      text-align-last: left;
      margin-top: 15px;
      // margin-left: 0;
      // margin-right: 10px;
    }
    &-second {
      text-align: right;
      margin-top: 15px;
      // margin-right: 0;
      // margin-left: 10px;
      input {
        text-align: right;
      }
    }
  }
  .ui-slider-handle {
    &:after {
      display: none;
      // display: block;
      position: absolute;
      content: attr(data-content);
      top: 0;
      left: 50%;
      margin: 30px 0 0 -50px;
      width: 100px;
      text-align: center;
    }
  }

  .ui-slider-horizontal .ui-slider-range {
    background-color: #ec7842;
  }

  .ui-state-default,
  .ui-widget-content .ui-state-default,
  .ui-widget-header .ui-state-default,
  .ui-button,
  html .ui-button.ui-state-disabled:hover,
  html .ui-button.ui-state-disabled:active {
    border: 1px solid $bggray;
    border: 0;
    border-radius: 50%;
    width: 20px;
    height: 20px;
    background: #fff;
    // box-shadow: inset 0 0 0 5px #fff;
    box-shadow: 0 0 11px 1px rgba($gray, 0.5);
    font-weight: normal;
    color: $black;
    outline: none;
    cursor: pointer;
    &:before {
      content: "";
      display: block;
      width: 10px;
      height: 10px;
      background-color: #ec7842;
      border-radius: 50%;
      position: absolute;
      top: 5px;
      left: 5px;
    }
  }

  .ui-slider-horizontal .ui-slider-handle {
    top: -10px;
  }

  #js-ui-slider-days {
    cursor: pointer;
  }
}

.mobile-filter {
  display: block;
  @include laptop {
    display: none;
    position: fixed;
    top: 0;
    left: 0;
    right: 100%;
    bottom: 0;
    z-index: 10;
    padding: 0;
    overflow-y: auto;
    overflow-x: hidden;
    width: 300px;
    background-color: #fff;
    box-shadow: 0px 0px 20px rgba($gray, 0.3);
  }
  @include phones {
    width: 100%;
  }
  // @include phonesm {
  // 	position: absolute;
  // }
}
#filterButton {
  height: 100%;
  button {
    padding: 18px;
    cursor: pointer;
    &:hover {
      background-color: #fff;
    }
  }
}
