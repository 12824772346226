.kamin-gallery {
		display: flex;
		align-items: center;
		height: 100%;
    text-align: center;
    .kamin-gallery__img {
        // width: 600px;
        height: 400px;
				overflow: hidden;
				@include phones {
					height: auto;
				}
				a {
					width: 100%;
					height: 100%;
					display: flex;
					justify-content: center;
					align-items: center;
					// flex: 1 1 auto;
					img {
            // width: 100%;
						// height: 100%;
						max-width: 100%;
					}
				}
    }
    .kamin-gallery__list {
        display: flex;
        flex-direction: column;
				justify-content: center;
				height: 415px;
				overflow: hidden;
				@include phones {
					flex-direction: row;
					flex-wrap: wrap;
					justify-content: space-around;
					height: 100%;
				}
				.kamin-gallery__item,
				.cmlex-tovar-photos {
					display: block;
            width: 110px;
						height: 100px;
						margin: 5px;
            // overflow: hidden;
						// opacity: 0.7;
						@include phones {
							margin-top: 10px;
						}
            img {
                // margin: 0 15px;
								width: 100%;
								height: auto;
								display: block;
            }
            &:hover {
                opacity: 1;
            }
        }
    }
}

.kamin-description {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: stretch;
    // height: 445px;
    padding: 40px;
		height: 100%;
		@include phones {
			padding: 20px;
		}
    .kamin-description__title {
        display: flex;
        justify-content: space-between;
        .title span, .title h1 {
						font-size: 1.7rem;
						line-height: 1.7rem;
        }
        a {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 30px;
            height: 30px;
            &:after {
                content: "";
                display: block;
                @include sprite($heart);
            }
            &:hover,
            &:active {
                &:after {
                    @include sprite($heart-a);
                }
            }
        }
    }
    .kamin-description__number {
        color:$gray;
        font-size: 1rem;
    }
    p {
        // white-space: nowrap;
        overflow: hidden;
        font-size: 0.95rem;
        line-height: 0.95rem;
        margin: 0;
        padding: 0;
        text-overflow: ellipsis;
				height: 106px;
				margin-top: 20px;
    }
    .kamin-description__price {
        display: flex;
        justify-content: space-between;
        margin-bottom: 20px;
        span {
					font-family: "Roboto";
					font-weight: bold;
					font-size: 1.5rem;
					color:$red;
					span {
						font-weight: normal;
						&:first-child {
							color: #7e7e7e;
						}
					}
        }
        a {
            color:$black;
            text-decoration: underline;
            text-decoration-style: dotted;
            font-size: 1rem;
            &:hover {
                text-decoration: none;
                color:$red;
            }
        }
    }
    .kamin-description__footer {
        display: flex;
        justify-content: space-around;
        flex-wrap: wrap;
        button {
            padding: 20px 30px;
            font-size: 0.9rem;
            text-transform: uppercase;
            cursor: pointer;
            margin: 5px;
        }
    }
}

.kamin-tabs,
.kamin-docs {
	margin-left: 20px;
	margin-bottom: 20px;
	margin-right: 20px;
    p {
        padding: 10px 20px;
        margin-bottom: 0;
		}
		table {
			margin: 20px 0;
		}
}

.kamin-tabs {
	.nav-link {
		@include phones {
			text-align: center;
		}
	}
	// .owl-stage-outer {
	// 	z-index: 2;
	// }
	.owl-nav {
		position: absolute;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
		z-index: -1;
		.owl-next, .owl-prev {
			position: absolute;
			color: transparent;
			right: -10px;
			top: 50%;
			margin-top: -7px;
			@include phones {
				right: -14px;
			}
			&:after {
				content: "";
				display: block;
				@include sprite($arrow-right-small-gray);
			}
			&:hover {
				&:after {
					@include sprite($arrow-right-small-red);
				}
			}
		}
		.owl-prev {
			left: -10px;
			right: auto;
			transform: rotate(180deg);
			// z-index: 3;
			@include phones {
				left: -14px;
			}
		}
	}
}

.kamin-docs {
	.nav-tabs .nav-link.active, .nav-tabs .nav-item.show .nav-link {
			color:$black;
			border-bottom-color: transparent;
			cursor: default;
	}
	p {
			span {
				font-family: "Roboto";
				font-weight: bold;
					color:$black;
			}
			a {
					font-family: 'Roboto';
					color: #0056b3;
					text-decoration: underline;
					text-decoration-style: dotted;
					&:hover {
							text-decoration: none;
					}
			}
	}
	a {
		font-family: 'Roboto';
		color: #0056b3;
		text-decoration: underline;
		text-decoration-style: dotted;
		&:hover {
				text-decoration: none;
		}
	}
	table {
		width: 100% !important;
	}
}