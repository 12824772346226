.advantages {
    margin-top: 30px;
}
.advantages__list {
    display: flex;
    justify-content: space-between;
    .advantages__item {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: flex-start;
        border-left:1px solid $bggray;
        padding: 20px 30px;
        // .advantages__item-img {
            // &:after {
            //     content: "";
            //     display: block;
            //     @include sprite($design_plan);
            // }
        // }
        img {
            display: block;
            margin-top: 20px;
        }
        span {
					font-family: "Roboto";
					font-weight: bold;
            font-size: 0.95rem;
            color:$black;
            margin-top: 20px;
            padding-right: 20px;
        }
        p {
            font-family: "Roboto";
            font-size: 0.9rem;
            color:$gray;
            margin-top: 20px;
        }
        @include laptop {
            align-items: center;
            text-align: center;
        }
    }
    @include laptop {
        justify-content: center;
        flex-wrap: wrap;
    }
}
