.carousel-product_item-hover {
    display: flex;
    flex-direction: row;
    justify-content: center;
    position: absolute;
    top:0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba($color: #000000, $alpha: 0.3);
    transition: background-color 1s;
    span {
        display: none;
        position: absolute;
        bottom: 10px;
        font-family: "Roboto";
				font-weight: bold;
        font-size: 1.1rem;
        line-height: 3.2rem;
        color:#fff;
        transition: bottom 1s;
    }
    a {
        display: none;
        position: absolute;
        bottom: 10px;
        transition: all 1s;
        font-size: 0.9rem;
    }
}

.carousel-product__item {
		// width: 100%;
		min-width: 200px;
		height: 300px;
		padding: 0 20px;
    position: relative;
    z-index: 1;
    display: flex;
    flex-direction: row;
		justify-content: center;
		align-items: center;
		img {
			margin: 20px 10px;
		}
    .carousel-product__item-text {
        position: absolute;
        left: 0;
        right: 0;
        bottom: 10px;
        text-align: center;
        color:#fff;
        margin: 0 auto;
        z-index: 2;
        transition: bottom 0.5s;
        font-family: "Roboto";
				font-weight: bold;
				padding: 0 5px;
    }
    &:hover {
        .carousel-product__item-text {
            bottom:200px;
        }
        .carousel-product_item-hover {
            background-color: rgba($color: #000000, $alpha: 0.7);
            span {
                display: block;
                bottom: 150px;
            }
            a {
                display: block;
                bottom: 100px;
            }
        }
        
    }
}



