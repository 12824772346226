.footer__wrp {
    border-top: 2px solid $bggrayd;
    padding: 40px 0;
}

.footer-bottom {
    background-color: $bggrayd;
}

.between__wrap {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}
.adress {
    display: flex;
    align-self: flex-end;
    font-size: 0.8rem;
		font-weight: 400;
		margin: 10px 0;
    @include phones {
        display: none;
    }
}

footer .social {
    align-self: flex-end;
    text-align: center;
    ul {
        display: inline-flex;
        li {
            font-size: 2rem;
            margin: 0 0.5rem;
            a {
								display: block;
                color:$gray;
                &:hover {
                    color:$red;
                }
            }
        }
    }
    @include phones {
        margin: 10px 0;
    }
}

.social {
    span {
        display: block;
        font-size: 0.95rem;
        color:$gray;
        margin-bottom: 10px;
        text-align: center;
    }
    li {
        margin: 0px 5px;
				opacity: 0.5;
				list-style: none;
				a {
					display: block;
				}
        &:hover {
            opacity: 1;
        }
		}
		.ya-share2__badge {
			overflow: visible;
		}
		.ya-share2__item {
			opacity: 0.8;
		}
		.ya-share2__counter {
			&:before {
				display: none;
			}
		}
    ul {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-around;
        @include tablets {
            justify-content: center;
        }
    }
    .vk {
        width: 10px;
        height: 10px;
        &:after {
            content: "";
            display: block;
            @include sprite($vkontakte);
        }
    }
    .odnoklassniki {
        width: 10px;
        height: 10px;
        &:after {
            content: "";
            display: block;
            @include sprite($ok);
        }
    }
    .facebook {
        width: 10px;
        height: 10px;
        &:after {
            content: "";
            display: block;
            @include sprite($facebook);
        }
    }
    .twitter {
        width: 10px;
        height: 10px;
        &:after {
            content: "";
            display: block;
            @include sprite($twitter);
        }
    }
    .youtube-play {
        width: 10px;
        height: 10px;
        &:after {
            content: "";
            display: block;
            @include sprite($youtube);
        }
		}
		.insta {
			width: 10px;
			height: 10px;
			&:after {
					content: "";
					display: block;
					@include sprite($insta);
			}
		}
}

// .between__wrap {
//     @include phones {
//         display: flex;
//         flex-direction: column;
//         justify-content: center;
//     }
// }

.email-phone__wrap {
    @include phones {
        display: flex;
        flex-direction: column;
        justify-content: center;
    }
}

footer .phone-head {
    &:before {
        content: "";
        display: inline-block;
        @include sprite($phone);
        margin-right: 10px;
    }
}

footer .email {
    &:before {
        content: "";
        display: inline-block;
        @include sprite($mail);
        margin-right: 10px;
    }
}

footer .phone-head,
footer .email {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 10px;
    a {
        display: block;
        font-size: 1.2rem;
        font-weight: 500;
        color: $black;
        text-decoration: none;
    }
    @include phones {
        justify-content: center;
    }
}
.callback {
    display: flex;
    align-self: flex-end;
    a {
        text-transform: uppercase;
    }
}

.copyright,
.wigos {
    padding: 10px 0;
    font-size: 0.8rem;
    color:$black;
    a {
				color:$black;
				margin-right: 5px;
    }
    @include phones {
        padding: 5px 0;
    }
}