.main-menu {
	position: relative;
	z-index: 999;
	display: flex;
	justify-content: space-between;
	@include laptop {
		display: none;
	}
	&:hover {
		box-shadow: 0px 9999px 0px 9999px rgba(0, 0, 0, 0.5);
		
	}
	&__list {
		cursor: pointer;
		li {
			position: relative;
			display: inline-block;
			a {
				display: block;
				padding: 20px;
				text-decoration: none;
				color: #000;
				&:hover {
					color: $red;
				}
			}
			&:hover {
				ul {
					display: block;
				}
			}
			// sub menu 1 lvl
			ul {
				position: absolute;
				top: 60px;
				left: 0;
				display: block;
				background-color: #fff;
				padding: 5px;
				border-top: 1px solid rgba($red,0.3);
				// border-left: 1px solid rgba($red,0.3);
				display: none;
				li {
					display: block;
					a {
						padding: 5px 15px;
						white-space: nowrap;
					}
					&:hover {
						ul {
							display: block !important;
							
						}
					}
					// sub menu 2 lvl
					ul {
						position: absolute;
						top: 0;
						left: 100%;
						display: none !important;
						li {
							display: block;
							a {

							}
							&:hover {
								ul {
									display: block;
									visibility: visible;
								}
							}
							// sub menu 3 lvl
							ul {
								display: none;
								visibility: hidden;
								li {
									display: block;
									a {

									}
								}
							}
						}
					}
					
				}
				li.arrow {
					&:after {
						transform: rotate(270deg);
					}
					&:hover {
						&:after {
							transform: rotate(90deg);
						}
					}
				}
			}
		}
		li.arrow {
			&:after {
				content: "";
				position: absolute;
				top: 50%;
				right: 5px;
				margin-top: -4px;
				display: block;
				@include sprite($arrow-down);
				transition: all 0.3s;
			}
			&:hover {
				&:after {
					transform: rotate(180deg);
				}
			}
		}
	}
	&__list-red {
		background-color: $red;
		border-top: 1px solid rgba($red,0.3);
		border-left: 1px solid rgba($red,0.3);
		&:hover {
			background-color: #fff;
			li {
				a {
					color: $red;
				}
				ul {
					li {
						a {
							color: #000;
							&:hover {
								color: $red;
							}
						}
					}
				}
			}
		}
		li {
			position: relative;
			display: inline-block;
			a {
				display: block;
				padding: 20px;
				text-decoration: none;
				color: #fff;
				&:hover {
					color: $red;
				}
			}
			&:hover {
				ul {
					display: block;
				}
			}
			// sub menu 1 lvl
			ul {
				position: absolute;
				top: 60px;
				right: 0;
				display: block;
				background-color: #fff;
				padding: 5px;
				border-top: 1px solid rgba($red,0.3);
				border-right: 1px solid rgba($red,0.3);
				display: none;
				li {
					display: block;
					a {
						padding: 5px 15px;
						white-space: nowrap;
						color: #000;
						text-align: right;
					}
					&:hover {
						ul {
							display: block !important;
							
						}
					}
					// sub menu 2 lvl
					ul {
						position: absolute;
						top: 0;
						right: 100%;
						display: none !important;
						li {
							display: block;
							a {

							}
							&:hover {
								ul {
									display: block;
								}
							}
							// sub menu 3 lvl
							ul {
								display: none;
								li {
									display: block;
									a {

									}
								}
							}
						}
					}
					
				}
				li.arrow {
					&:after {
						content: "";
						position: absolute;
						top: 50%;
						left: 5px;
						margin-top: -4px;
						display: block;
						@include sprite($arrow-down);
						transform: rotate(90deg);
						transition: all 0.3s;
					}
					&:hover {
						&:after {
							transform: rotate(270deg);
						}
					}
				}
			}
		}
	}
}

// вторая попытка
// .menu-main {
//   border-top: 1px solid $bggray;
//   display: flex;
//   justify-content: space-between;
//   @include laptop {
//     display: none;
//   }
//   ul.menu-main__list {
//     // display: inline-block;
//     // display: flex;
//     // flex-direction: row;
//     // justify-content: space-between;
//     // list-style-type: none;
//     // white-space: nowrap;
//     li.menu-main__item.arrow,
//     li.flexMenu-viewMore {
//       position: relative;
//       &:after {
//         content: "";
//         position: absolute;
//         top: 50%;
//         right: 3px;
//         margin-top: -3px;
//         display: block;
//         @include sprite($arrow-down);
//       }
//       &:hover {
//         &:after {
//           transform: rotate(180deg);
//         }
//       }
//     }

//     li.menu-main__item,
//     li.flexMenu-viewMore {
//       display: inline-block;
//       .menu-main__dropdown {
//         display: none;
//         position: absolute;
//         top: 65px;
//         background-color: #fff;
//         z-index: 16;
//         min-width: 200px;
//         border-top: 1px solid $bggray;
//         // padding: 10px;
//         @include desktopL {
//           top: 63px;
//         }
//         @include desktop {
//           top: 59px;
//         }
//         @include laptop {
//           top: 47px;
//         }
//         li.arrow {
//           &:after {
//             content: "";
//             position: absolute;
//             top: 50%;
//             right: 3px;
//             margin-top: -3px;
//             display: block;
//             @include sprite($arrow-down);
//             transform: rotate(270deg);
//           }
//           &:hover {
//             &:after {
//               transform: rotate(90deg);
//             }
//           }
//         }
//         li a:hover {
//           color: $red;
//         }
//         .main-menu__list-first,
//         .main-menu__list-second,
//         .main-menu__list-third {
//           padding: 20px 0;
//           width: 100%;
//           // li {
//           // padding: 0 20px;
//           // }
//           li a {
//             padding: 5px 20px;
//           }
//           img {
//             width: 50%;
//             filter: $gray;
//             filter: grayscale(1);
//             &:hover {
//               filter: none;
//             }
//           }
//         }
//         .main-menu__list-second,
//         .main-menu__list-first {
//           display: flex;
//           flex-direction: column;
//           align-items: flex-start;
//           justify-content: flex-start;
//           flex-wrap: nowrap;
//           .main-menu__list-title {
//             font-weight: bold;
//             margin-bottom: 5px;
//             margin-left: 20px;
//           }
//           li {
//             // white-space: pre-wrap;
//             width: 100%;
//             position: relative;
//             max-width: 200px;
//             a {
//               display: flex;
//               flex-direction: row;
//               justify-content: space-between;
//               align-items: center;
//               white-space: pre-wrap;
//               // &:after {
//               //     content: "";
//               //     display: block;
//               //     width: 20px;
//               //     height: 20px;
//               //     @include sprite($arrow-right-small-red);
//               //     margin-left: 20px;
//               // }
//             }
//           }
//         }
//         .main-menu__list-second {
//           li {
//             a {
//               &:after {
//                 display: none;
//               }
//             }
//           }
//         }
//         .main-menu__list-third {
//           display: flex;
//           flex-direction: column;
//           align-items: flex-start;
//           justify-content: flex-start;
//           flex-wrap: wrap;
//           border-left: 1px solid $bggray;
//           .main-menu__list-title {
//             font-weight: bold;
//             margin-bottom: 5px;
//           }
//         }
//       }
//       &:hover .menu-main__dropdown {
//         display: flex;
//         flex-direction: row;
//         box-shadow: 0px 9999px 0px 9999px rgba(0, 0, 0, 0.5);
//         animation: fade-in 0.3s linear;
//       }
//       // .individ-project {
//       // 	padding: 0;
//       // 	.main-menu__list-first,
//       // 	.main-menu__list-second,
//       // 	.main-menu__list-third {
//       // 		padding: 0px;
//       // 		li a {
//       // 			padding: 10px 20px;
//       // 		}
//       // 	}
//       // }
//       a {
//         display: block;
//         color: $black;
//         // font-family: 'RobotoRegular';
//         font-size: 1rem;
//         padding: 20px 15px;
//         font-family: "roboto", sans-serif;
//         font-style: normal;
//         font-weight: 400;
//         white-space: nowrap;
//         &:hover {
//           text-decoration: none;
//           color: $red;
//         }
//         @include desktopL {
//           // font-size: 0.94rem;
//         }
//         @include desktop {
//           padding: 20px 10px;
//         }
//         @include laptop {
//           padding: 15px 5px;
//           // font-size: 0.9rem;
//         }
//       }
//       // &:last-child {
//       //   .menu-main__dropdown-submenu {
//       //     left: -100%;
//       //     right: 100%;
//       //   }
//       //   background-color: $red;
//       //   &:after {
//       //     display: none;
//       //   }
//       //   &:hover {
//       //     background-color: #fff;
//       //     a {
//       //       color: $red;
//       //     }
//       //   }
//       //   a {
//       //     color: #fff;
//       //   }
//       //   .main-menu__list-first {
//       //     padding: 0;
//       //     li {
//       //       white-space: pre-wrap;
//       //       a {
//       //         color: $black;
//       //         padding: 10px 20px;
//       //         &:after {
//       //           display: none;
//       //           margin-right: 10px;
//       //           padding-right: 10px;
//       //         }
//       //         &:hover {
//       //           background-color: $red;
//       //           color: #fff;
//       //           // &:after {
//       //           // 	@include sprite($arrow-right-small-gray);
//       //           // }
//       //         }
//       //       }
//       //     }
//       //   }
//       // }
//     }
//     li.flexMenu-viewMore {
//       &:hover {
//         .menu-main__dropdown {
//           display: block;
//         }
//       }
// 		}
		
//     li.menu-main__item-last-child {
//       position: relative;
//       .menu-main__dropdown-submenu {
//         left: -100%;
//         right: 100%;
// 			}
// 			color: #fff;
//       background-color: $red;
//       &:after {
//         display: none;
//       }
//       &:hover {
//         background-color: #fff;
//         a {
//           color: $red;
//         }
//       }
//       a {
//         color: #fff;
//       }
//       .main-menu__list-first {
//         padding: 0;
//         li {
//           white-space: pre-wrap;
//           a {
//             color: $black;
// 						padding: 10px 20px;
// 						white-space: pre-wrap;
//             &:after {
//               display: none;
//               margin-right: 10px;
//               padding-right: 10px;
//             }
//             &:hover {
//               background-color: $red;
//               color: #fff;
//               // &:after {
//               // 	@include sprite($arrow-right-small-gray);
//               // }
//             }
//           }
//         }
//       }
// 		}
		
// 	}
// 	ul.menu-main__list-red {
// 		li.menu-main__item {
// 			a {
// 				color: #fff;
// 			}
// 		}
// 		.menu-main__dropdown-submenu {
// 			left: -100%;
// 			right: 100%;
// 		}
// 		background-color: $red;
// 		&:after {
// 			display: none;
// 		}
// 		&:hover, &:focus {
// 			background-color: #fff;
// 			a {
// 				color: $red;
// 			}
// 		}
// 		a {
// 			color: #fff;
// 		}
// 		.main-menu__list-first {
// 			padding: 0;
// 			li {
// 				white-space: pre-wrap;
// 				a {
// 					color: $black;
// 					padding: 10px 20px;
// 					&:after {
// 						display: none;
// 						margin-right: 10px;
// 						padding-right: 10px;
// 					}
// 					&:hover {
// 						background-color: $red;
// 						color: #fff;
// 						// &:after {
// 						// 	@include sprite($arrow-right-small-gray);
// 						// }
// 					}
// 				}
// 			}
// 		}
// 	}
//   i {
//     margin-left: 10px;
//   }
//   .menu-main__dropdown-submenu {
//     display: none;
//     position: absolute;
//     top: -1px;
//     left: 100%;
//     background-color: #fff;
//     // padding: 20px 0;
//     border-top: 1px solid #f5f5f5;
//     ul {
//       min-width: 200px;
//     }
//   }
//   .dropdown-submenu.arrow-sub {
//     position: relative;
//     &:after {
//       content: "";
//       position: absolute;
//       top: 50%;
//       right: 5px;
//       margin-top: -7px;
//       display: block;
//       @include sprite($arrow-right-small-gray);
//     }
//   }
//   .dropdown-submenu {
//     &:hover {
//       .menu-main__dropdown-submenu {
//         display: block;
//       }
//       &:after {
//         @include sprite($arrow-right-small-red);
//       }
//     }
//   }
// }
// конец вторая попытка







/* okayNav styles */
// body.okayNav-loaded {
//   overflow-x: hidden;
// }
// .okayNav:not(.loaded) {
//   visibility: hidden;
//   -webkit-user-select: none;
//      -moz-user-select: none;
//       -ms-user-select: none;
//           user-select: none;
// }
// .okayNav ul {
//   /* We want two navigations - one hidden and one visible */
//   float: left;
// }
// .okayNav a {
//   /* Links general styling */
//   color: #2e2e33;
//   font-size: 1.4rem;
//   font-weight: bold;
//   position: relative;
//   z-index: 1;
// }
// .okayNav__nav--visible {
//   overflow: hidden;
//   white-space: nowrap;
// }
// .okayNav__nav--visible li {
//   display: inline-block;
// }
// .okayNav__nav--visible a {
//   /* Link styling for the visible part of the nav */
//   display: block;
//   transition: color 200ms cubic-bezier(0.55, 0, 0.1, 1);
//   padding: 15px 15px;
// }
// .okayNav__nav--visible a:hover {
//   color: #546edb;
// }
// /* Link styling for the off-screen part of the nav */
// .okayNav__nav--invisible {
//   position: fixed;
//   top: 70px;
//   bottom: 0;
//   overflow-y: scroll;
//   -webkit-overflow-scrolling: touch;
//   background: #fff;
// }
// .okayNav__nav--invisible a {
//   background: #fff;
//   width: 240px;
//   display: block;
//   padding: 15px;
// }
// .okayNav__nav--invisible.nav-left {
//   left: 0;
//   -webkit-transform: translateX(-100%);
//           transform: translateX(-100%);
// }
// .okayNav__nav--invisible.nav-right {
//   right: 0;
//   -webkit-transform: translateX(100%);
//           transform: translateX(100%);
// }
// .okayNav__nav--invisible.transition-enabled {
//   transition: -webkit-transform 400ms cubic-bezier(0.55, 0, 0.1, 1);
//   transition: transform 400ms cubic-bezier(0.55, 0, 0.1, 1);
//   transition: transform 400ms cubic-bezier(0.55, 0, 0.1, 1), -webkit-transform 400ms cubic-bezier(0.55, 0, 0.1, 1);
// }
// .okayNav__nav--invisible.nav-open {
//   -webkit-transform: translateX(0);
//           transform: translateX(0);
// }
// /* Kebab icon */
// .okayNav__menu-toggle {
//   position: relative;
//   transition: -webkit-transform 400ms cubic-bezier(0.55, 0, 0.1, 1);
//   transition: transform 400ms cubic-bezier(0.55, 0, 0.1, 1);
//   transition: transform 400ms cubic-bezier(0.55, 0, 0.1, 1), -webkit-transform 400ms cubic-bezier(0.55, 0, 0.1, 1);
//   cursor: pointer;
//   width: 40px;
//   height: 20px;
//   float: left;
//   z-index: 1;
//   top: 15px;
// }
// .okayNav__menu-toggle.okay-invisible {
//   position: absolute;
//   opacity: 0;
//   -webkit-user-select: none;
//      -moz-user-select: none;
//       -ms-user-select: none;
//           user-select: none;
//   pointer-events: none;
// }
// .okayNav__menu-toggle span {
//   display: block;
//   position: absolute;
//   margin: auto;
//   height: 4px;
//   width: 4px;
//   background: #2e2e33;
//   border-radius: 50%;
//   left: 0;
//   right: 0;
//   pointer-events: none;
// }
// .okayNav__menu-toggle span:nth-child(1) {
//   top: 0;
// }
// .okayNav__menu-toggle span:nth-child(2) {
//   top: 50%;
//   -webkit-transform: translateY(-50%);
//           transform: translateY(-50%);
// }
// .okayNav__menu-toggle span:nth-child(3) {
//   bottom: 0;
// }
// .okayNav__menu-toggle.icon--active {
//   /* Kebab icon when off-screen nav is open */
//   -webkit-transform: rotate(90deg);
//           transform: rotate(90deg);
// }
// .okayNav__menu-toggle.icon--active span {
//   background: #546edb;
// }
