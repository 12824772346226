.slider__wrap {
    display: flex;
    flex-direction: row;
    padding: 20px 0;
    span {
        display: block;
        font-family: "Roboto";
				font-weight: bold;
        font-size: 2rem;
        font-weight: 600;
        // color:$black;
        margin-bottom: 10px;
    }
    @include phones {
        display: none;
    }
}

.slider__right {
    margin-left: 20px;
    span {
        color:#fff;
    }
}

.carousel-caption {
    position: absolute;
    left: 20px;
		top: 20px;
		bottom: auto;
    z-index: 10;
    padding: 20px;
		text-align: left;
		background-color: rgba(0, 0, 0, 0.3);
}

.carousel-indicators li {
    width: 15px;
    height: 15px;
    border-radius: 50%;
    background-color: rgba(255,255,255,0.3);
    cursor: pointer;
}

