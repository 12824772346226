a.button {
    // display: block;
    padding: 10px 40px;
    border-radius: 0px;
    text-align: center;
    text-transform: uppercase;
    cursor: pointer;
    &:hover {
        text-decoration: none;
    }
}

a.button-white {
    border:1px solid #fff;
    color:#fff;
    &:hover {
        border:1px solid #fff;
        background-color: rgba($color:#fff, $alpha: 0.3);
        color:#fff;
    }
}
a.button-black {
    border:1px solid $black;
    color: $black;
    &:hover {
        border:1px solid $black;
        background-color: rgba($color:$black, $alpha: 0.1);
        color:$black;
    }
}
a.button-red,
button.button-red-outline,
button.button-red {
    border:1px solid $red;
    color: $red;
    background-color: transparent;
    &:hover {
        border:1px solid $red;
        background-color: $red;
        color:#fff;
        background-color: $red;
    }
}

button.button-red {
    background-color: $red;
    color:#fff;
    &:hover {
        background-color: transparent;
        color:$red;
    }
}