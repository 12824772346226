.kamins {
  .sort {
    display: flex;
    justify-content: center;
    align-items: center;
    span {
			margin: 18px;
			@include phonesm {
				margin: 18px 5px;
			}
    }
    select {
      color: $red;
      text-decoration: underline;
      text-decoration-style: dotted;
      border: none;
      cursor: pointer;
      outline: none;
      option {
        text-decoration: underline;
        text-decoration-style: dotted;
        background: none;
        outline: none;
        border: none;
      }
    }
  }
  .block {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 60px;
    height: 60px;
    border-left: 1px solid $bggray;
    float: left;
    &:after {
      content: "";
      display: block;
      @include sprite($block);
    }
    &:hover,
    &:active,
    &:focus {
      &:after {
        @include sprite($block-a);
      }
    }
    // &:hover {
    //     box-shadow: inset 0px 0px 0px 1px $red;
    // }
  }
  .list {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 60px;
    height: 60px;
    border-left: 1px solid $bggray;
    &:after {
      content: "";
      display: block;
      @include sprite($list);
    }
    &:hover,
    &:active,
    &:focus {
      &:after {
        @include sprite($list-a);
      }
    }
  }
  .kamin-list {
    margin-left: -15px;
    margin-right: -15px;
  }
  margin-bottom: 40px;
  a.button {
    padding: 18px 40px;
    display: block;
  }
  .pagination {
    .page-item {
			list-style-type: none;
			border: 1px solid #ededed;
      .page-link {
				height: 100%;
        padding: 10px 20px;
        border-color: $bggray;
        border-radius: 0;
				color: $gray;
				@include phones {
					padding: 10px;
				}
        &:hover {
          background-color: #fff;
          color: $red;
        }
        &:active {
          font-weight: bold;
				}
				&:focus {
					box-shadow: none;
				}
				&__next, &__prev {
					@include phonesm {
						display: none;
					}
					&:after {
						content: "";
						display: block;
						@include sprite($arrow-right);
					}
					&:hover {
						opacity: 0.7;
					}
					&:active {
						opacity: 0.5;
					}
				}
				&__prev {
					&:after {
						transform: rotate(180deg);
					}
				}
			}
			&.active {
				a {
					background-color: $red !important;
					cursor: default;
				}
			}
    }
  }
}

.pagination {
	.page-item {
		list-style-type: none;
	}
}

.mobile-none {
	p {
		margin-bottom: 0;
	}
	.cmlex-row {
		@include phones {
			display: none;
		}
	}
}