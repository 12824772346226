.news-about {
    margin-top: 30px;
}

.index-news {
    .index-news__item {
        padding: 10px 30px;
        border-bottom: 1px solid $bggray;
    }
    a {
        display: block;
        color:$black;
        font-family: "Roboto";
				font-weight: bold;
        font-size: 1.0rem;
        font-weight: 500;
        text-decoration: none;
        &:hover {
            color:$red;
            text-decoration: underline;
        }
    }
    strong {
			font-family: "Roboto";
			font-weight: bold;
        font-size: 0.95rem;
        font-weight: normal;
    }
    p  {
        font-size: 0.9rem;
        margin: 0;
        color:$gray;
    }
}

.index-about {
    display: flex;
    flex-direction: row;
    img {
        // width: 300px;
        width: 100%;
        height: 300px;
        overflow: hidden;
        @include phones {
            width: 100%;
            height: 200px;
        }
    }
    .index-about__text {
        font-family: "Roboto";
        font-size: 0.95rem;
        padding: 20px;
        height: 300px;
        overflow-y: scroll;
        color:$gray;
        ::-webkit-scrollbar {
            width: 1rem;
        }
        
        ::-webkit-scrollbar-track {
            box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
        }
    
        ::-webkit-scrollbar-thumb {
            background-color: darkgrey;
            outline: 1px solid slategrey;
        }
    }
}