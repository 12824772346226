// color global

$bggray: #ededed;
$bggrayd:#c0c0c0;

$grayl:#b2b2b2;
$gray: #7e7e7e;
$black: #010101;

$red: #d21d1d;

