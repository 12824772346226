.modal {
	.modal-dialog {
    max-width: 700px;
	}
	.modal-content {
		border: none;
		border-radius: 0;
		.modal-header {
			border-bottom: 0;
			padding-left: 40px;
			@include phones {
				padding-left: 10px;
			}
			.modal-title {
				font-size: 1.5rem;
			}
			.close {
				background-color: $red;
				color: #fff;
				opacity: 0.8;
				&:hover {
					opacity: 1;
				}
				// span {
				// 	color: #fff;
				// }
			}
		}
		.modal-body {
			padding-left: 40px !important;
			padding-right: 40px !important;
			@include phones {
				padding-right: 10px !important;
				padding-left: 10px !important;
			}
			label {
				margin-left: 10px;
				padding: 0px 10px;
				background-color: #fff;
				font-size: 14px;
				color: gray;
			}
			.form-control {
				margin-top: -20px;
				border: 1px solid #dee2e6;
				border-radius: 0;
				padding: 20px;
			}
			.policy-info {
				color: gray;
			}
		}
		.modal-footer {
			padding-right: 30px;
			padding-bottom: 30px;
			border-top: 0;
			@include phones {
				padding-right: 10px;
				padding-bottom: 10px;
			}
			.mybutton {
				border-radius: 0;
				padding: 15px 30px;
				&:hover {
					box-shadow: none;
					background-color: transparent;
				}
			}
			.mybutton.red {
				background-color: $red;
				border:1px solid $red;
				&:hover {
					background-color: transparent;
					color: $red;
				}
			}
			.mybutton.gray {
				border:1px solid #a9a9a9;
				&:hover {
					color: #a9a9a9;
				}
			}
		}
	}
}