a#top {
	width: 40px;
	height: 40px;
	overflow: hidden;
	z-index: 99;
	display: none;
	background-color: rgba($color: $red, $alpha: 0.8);
	position: fixed;
	bottom: 60px;
	right: 20px;
	@include phones {
		left: 20px;
		right: 100%;
		bottom: 20px;
	}
	&:after {
			content: "";
			display: block;
			width: 0;
			height: 0;
			margin: 14px auto;
			border-left: 10px solid transparent;
			border-right: 10px solid transparent;
			border-bottom: 10px solid #fff;
	}
	&:hover {
			background-color: $red;
	}
}