.faq {
    // margin-bottom: 100px;
    .card {
        border:0;
        .card-header {
            // display: flex;
            // justify-content: space-between;
            // align-items: center;
            background-color: #fff;
            border-bottom: 0;
            border-top:1px solid $bggray;
            padding: 0;
            button {
                display: flex;
                justify-content: space-between;
                align-items: center;
                font-family: "Roboto";
								font-weight: bold;
                font-size: 0.95rem;
                color:$black;
                text-decoration: none;
                width: 100%;
                padding: 20px;
                &:hover {
                    color:$red;
                }
                &:after {
                    content: "";
                    display: inline-block;
                    @include sprite($arrow-down);
                }
            }
        }
        .card-body {
            .checkbox {
                display: none;
            }
            .checkbox-custom {
                position: relative;
                width: 17px;
                height: 17px;
                border: 1px solid $black;
                margin-right: 10px;
            }
            .checkbox-custom,
            .label {
                display: inline-block;
                vertical-align: middle;
                cursor: pointer;
                font-size: 0.9rem;
                color:$black;
                &:hover {
                    color:$red;
                }
            }
            .checkbox:checked + .checkbox-custom::before {
                content: "";
                display: block;
                position: absolute;
                top: 3px;
                right: 3px;
                bottom: 3px;
                left: 3px;
                background: $red;
            }
        }
    }
    .filter__buttons {
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: #fff;
        border-top: 1px solid $bggray;
        a {
            width: 50%;
            padding: 20px 0;
            border:1px solid transparent; 
        }
        a.button {
            color:$gray;
            &:hover {
                color:#fff;
                background-color: $red;
                border:1px solid $red;
                border-right-color: $bggray;
            }
        }
        a.button-red {
            color:#fff;
            background-color: $red;
            &:hover {
                color:$red;
                background-color: #fff;
                border:1px solid $red;
            }
        }
    }




    #js-ui-slider-price {
        width: 100%;
        height: 2px;
        margin: 50px 0px;
        background-color: $bggray;
        border: none;
        cursor: pointer;
    }
    .date {
        display: none;
    }
    .ui-slider-handle {
        &:after {
            display: block;
            position: absolute;
            content: attr(data-content);
            top: 0;
            left: 50%;
            margin: 30px 0 0 -50px;
            width: 100px;
            text-align: center;
        }
    }

    .ui-slider-horizontal .ui-slider-range {
        background-color: $red;
    }

    .ui-state-default, .ui-widget-content .ui-state-default, .ui-widget-header .ui-state-default, .ui-button, html .ui-button.ui-state-disabled:hover, html .ui-button.ui-state-disabled:active {
        border: 1px solid $bggray;
        border: 0;
        border-radius: 50%;
        width: 25px;
        height: 25px;
        background: #fff;
        // box-shadow: inset 0 0 0 5px #fff;
        box-shadow: 0 0 15px 1px $gray;
        font-weight: normal;
        color: $black;
        outline: none;
        cursor: pointer;
        &:before {
            content: "";
            display: block;
            width: 15px;
            height: 15px;
            background-color: $red;
            border-radius: 50%;
            position: absolute;
            top: 5px;
            left: 5px;
        }
    }

    .ui-slider-horizontal .ui-slider-handle {
        top:-11px;
    }

    #js-ui-slider-days {
        cursor: pointer;
    }
}