// SCSS variables are information about icon's compiled state, stored under its original file name
//
// .icon-home {
//   width: $icon-home-width;
// }
//
// The large array-like variables contain all information about a single icon
// $icon-home: x y offset_x offset_y width height total_width total_height image_path;
//
// At the bottom of this section, we provide information about the spritesheet itself
// $spritesheet: width height image $spritesheet-sprites;
$arrow-down-name: 'arrow-down';
$arrow-down-x: 122px;
$arrow-down-y: 86px;
$arrow-down-offset-x: -122px;
$arrow-down-offset-y: -86px;
$arrow-down-width: 8px;
$arrow-down-height: 7px;
$arrow-down-total-width: 150px;
$arrow-down-total-height: 121px;
$arrow-down-image: 'sprite.png';
$arrow-down: (122px, 86px, -122px, -86px, 8px, 7px, 150px, 121px, 'sprite.png', 'arrow-down', );
$arrow-left-name: 'arrow-left';
$arrow-left-x: 136px;
$arrow-left-y: 27px;
$arrow-left-offset-x: -136px;
$arrow-left-offset-y: -27px;
$arrow-left-width: 13px;
$arrow-left-height: 23px;
$arrow-left-total-width: 150px;
$arrow-left-total-height: 121px;
$arrow-left-image: 'sprite.png';
$arrow-left: (136px, 27px, -136px, -27px, 13px, 23px, 150px, 121px, 'sprite.png', 'arrow-left', );
$arrow-right-small-black-name: 'arrow-right-small-black';
$arrow-right-small-black-x: 136px;
$arrow-right-small-black-y: 105px;
$arrow-right-small-black-offset-x: -136px;
$arrow-right-small-black-offset-y: -105px;
$arrow-right-small-black-width: 8px;
$arrow-right-small-black-height: 14px;
$arrow-right-small-black-total-width: 150px;
$arrow-right-small-black-total-height: 121px;
$arrow-right-small-black-image: 'sprite.png';
$arrow-right-small-black: (136px, 105px, -136px, -105px, 8px, 14px, 150px, 121px, 'sprite.png', 'arrow-right-small-black', );
$arrow-right-small-gray-name: 'arrow-right-small-gray';
$arrow-right-small-gray-x: 136px;
$arrow-right-small-gray-y: 90px;
$arrow-right-small-gray-offset-x: -136px;
$arrow-right-small-gray-offset-y: -90px;
$arrow-right-small-gray-width: 8px;
$arrow-right-small-gray-height: 14px;
$arrow-right-small-gray-total-width: 150px;
$arrow-right-small-gray-total-height: 121px;
$arrow-right-small-gray-image: 'sprite.png';
$arrow-right-small-gray: (136px, 90px, -136px, -90px, 8px, 14px, 150px, 121px, 'sprite.png', 'arrow-right-small-gray', );
$arrow-right-small-red-name: 'arrow-right-small-red';
$arrow-right-small-red-x: 136px;
$arrow-right-small-red-y: 75px;
$arrow-right-small-red-offset-x: -136px;
$arrow-right-small-red-offset-y: -75px;
$arrow-right-small-red-width: 8px;
$arrow-right-small-red-height: 14px;
$arrow-right-small-red-total-width: 150px;
$arrow-right-small-red-total-height: 121px;
$arrow-right-small-red-image: 'sprite.png';
$arrow-right-small-red: (136px, 75px, -136px, -75px, 8px, 14px, 150px, 121px, 'sprite.png', 'arrow-right-small-red', );
$arrow-right-name: 'arrow-right';
$arrow-right-x: 136px;
$arrow-right-y: 51px;
$arrow-right-offset-x: -136px;
$arrow-right-offset-y: -51px;
$arrow-right-width: 13px;
$arrow-right-height: 23px;
$arrow-right-total-width: 150px;
$arrow-right-total-height: 121px;
$arrow-right-image: 'sprite.png';
$arrow-right: (136px, 51px, -136px, -51px, 13px, 23px, 150px, 121px, 'sprite.png', 'arrow-right', );
$arrow-top-name: 'arrow-top';
$arrow-top-x: 113px;
$arrow-top-y: 86px;
$arrow-top-offset-x: -113px;
$arrow-top-offset-y: -86px;
$arrow-top-width: 8px;
$arrow-top-height: 7px;
$arrow-top-total-width: 150px;
$arrow-top-total-height: 121px;
$arrow-top-image: 'sprite.png';
$arrow-top: (113px, 86px, -113px, -86px, 8px, 7px, 150px, 121px, 'sprite.png', 'arrow-top', );
$block-a-name: 'block-a';
$block-a-x: 50px;
$block-a-y: 54px;
$block-a-offset-x: -50px;
$block-a-offset-y: -54px;
$block-a-width: 24px;
$block-a-height: 24px;
$block-a-total-width: 150px;
$block-a-total-height: 121px;
$block-a-image: 'sprite.png';
$block-a: (50px, 54px, -50px, -54px, 24px, 24px, 150px, 121px, 'sprite.png', 'block-a', );
$block-name: 'block';
$block-x: 88px;
$block-y: 0px;
$block-offset-x: -88px;
$block-offset-y: 0px;
$block-width: 24px;
$block-height: 24px;
$block-total-width: 150px;
$block-total-height: 121px;
$block-image: 'sprite.png';
$block: (88px, 0px, -88px, 0px, 24px, 24px, 150px, 121px, 'sprite.png', 'block', );
$facebook-name: 'facebook';
$facebook-x: 136px;
$facebook-y: 0px;
$facebook-offset-x: -136px;
$facebook-offset-y: 0px;
$facebook-width: 14px;
$facebook-height: 26px;
$facebook-total-width: 150px;
$facebook-total-height: 121px;
$facebook-image: 'sprite.png';
$facebook: (136px, 0px, -136px, 0px, 14px, 26px, 150px, 121px, 'sprite.png', 'facebook', );
$heart-a-name: 'heart-a';
$heart-a-x: 88px;
$heart-a-y: 56px;
$heart-a-offset-x: -88px;
$heart-a-offset-y: -56px;
$heart-a-width: 24px;
$heart-a-height: 21px;
$heart-a-total-width: 150px;
$heart-a-total-height: 121px;
$heart-a-image: 'sprite.png';
$heart-a: (88px, 56px, -88px, -56px, 24px, 21px, 150px, 121px, 'sprite.png', 'heart-a', );
$heart-name: 'heart';
$heart-x: 0px;
$heart-y: 79px;
$heart-offset-x: 0px;
$heart-offset-y: -79px;
$heart-width: 24px;
$heart-height: 21px;
$heart-total-width: 150px;
$heart-total-height: 121px;
$heart-image: 'sprite.png';
$heart: (0px, 79px, 0px, -79px, 24px, 21px, 150px, 121px, 'sprite.png', 'heart', );
$insta-name: 'insta';
$insta-x: 0px;
$insta-y: 0px;
$insta-offset-x: 0px;
$insta-offset-y: 0px;
$insta-width: 30px;
$insta-height: 30px;
$insta-total-width: 150px;
$insta-total-height: 121px;
$insta-image: 'sprite.png';
$insta: (0px, 0px, 0px, 0px, 30px, 30px, 150px, 121px, 'sprite.png', 'insta', );
$list-a-name: 'list-a';
$list-a-x: 0px;
$list-a-y: 54px;
$list-a-offset-x: 0px;
$list-a-offset-y: -54px;
$list-a-width: 24px;
$list-a-height: 24px;
$list-a-total-width: 150px;
$list-a-total-height: 121px;
$list-a-image: 'sprite.png';
$list-a: (0px, 54px, 0px, -54px, 24px, 24px, 150px, 121px, 'sprite.png', 'list-a', );
$list-name: 'list';
$list-x: 25px;
$list-y: 54px;
$list-offset-x: -25px;
$list-offset-y: -54px;
$list-width: 24px;
$list-height: 24px;
$list-total-width: 150px;
$list-total-height: 121px;
$list-image: 'sprite.png';
$list: (25px, 54px, -25px, -54px, 24px, 24px, 150px, 121px, 'sprite.png', 'list', );
$mail-name: 'mail';
$mail-x: 113px;
$mail-y: 67px;
$mail-offset-x: -113px;
$mail-offset-y: -67px;
$mail-width: 22px;
$mail-height: 18px;
$mail-total-width: 150px;
$mail-total-height: 121px;
$mail-image: 'sprite.png';
$mail: (113px, 67px, -113px, -67px, 22px, 18px, 150px, 121px, 'sprite.png', 'mail', );
$menu-mobile-a-name: 'menu-mobile-a';
$menu-mobile-a-x: 0px;
$menu-mobile-a-y: 101px;
$menu-mobile-a-offset-x: 0px;
$menu-mobile-a-offset-y: -101px;
$menu-mobile-a-width: 23px;
$menu-mobile-a-height: 20px;
$menu-mobile-a-total-width: 150px;
$menu-mobile-a-total-height: 121px;
$menu-mobile-a-image: 'sprite.png';
$menu-mobile-a: (0px, 101px, 0px, -101px, 23px, 20px, 150px, 121px, 'sprite.png', 'menu-mobile-a', );
$menu-mobile-name: 'menu-mobile';
$menu-mobile-x: 75px;
$menu-mobile-y: 79px;
$menu-mobile-offset-x: -75px;
$menu-mobile-offset-y: -79px;
$menu-mobile-width: 23px;
$menu-mobile-height: 20px;
$menu-mobile-total-width: 150px;
$menu-mobile-total-height: 121px;
$menu-mobile-image: 'sprite.png';
$menu-mobile: (75px, 79px, -75px, -79px, 23px, 20px, 150px, 121px, 'sprite.png', 'menu-mobile', );
$ok-name: 'ok';
$ok-x: 88px;
$ok-y: 25px;
$ok-offset-x: -88px;
$ok-offset-y: -25px;
$ok-width: 18px;
$ok-height: 30px;
$ok-total-width: 150px;
$ok-total-height: 121px;
$ok-image: 'sprite.png';
$ok: (88px, 25px, -88px, -25px, 18px, 30px, 150px, 121px, 'sprite.png', 'ok', );
$phone-name: 'phone';
$phone-x: 113px;
$phone-y: 0px;
$phone-offset-x: -113px;
$phone-offset-y: 0px;
$phone-width: 22px;
$phone-height: 22px;
$phone-total-width: 150px;
$phone-total-height: 121px;
$phone-image: 'sprite.png';
$phone: (113px, 0px, -113px, 0px, 22px, 22px, 150px, 121px, 'sprite.png', 'phone', );
$search-a-name: 'search-a';
$search-a-x: 113px;
$search-a-y: 23px;
$search-a-offset-x: -113px;
$search-a-offset-y: -23px;
$search-a-width: 21px;
$search-a-height: 21px;
$search-a-total-width: 150px;
$search-a-total-height: 121px;
$search-a-image: 'sprite.png';
$search-a: (113px, 23px, -113px, -23px, 21px, 21px, 150px, 121px, 'sprite.png', 'search-a', );
$search-name: 'search';
$search-x: 113px;
$search-y: 45px;
$search-offset-x: -113px;
$search-offset-y: -45px;
$search-width: 21px;
$search-height: 21px;
$search-total-width: 150px;
$search-total-height: 121px;
$search-image: 'sprite.png';
$search: (113px, 45px, -113px, -45px, 21px, 21px, 150px, 121px, 'sprite.png', 'search', );
$shopping-cart-a-name: 'shopping-cart-a';
$shopping-cart-a-x: 62px;
$shopping-cart-a-y: 26px;
$shopping-cart-a-offset-x: -62px;
$shopping-cart-a-offset-y: -26px;
$shopping-cart-a-width: 25px;
$shopping-cart-a-height: 25px;
$shopping-cart-a-total-width: 150px;
$shopping-cart-a-total-height: 121px;
$shopping-cart-a-image: 'sprite.png';
$shopping-cart-a: (62px, 26px, -62px, -26px, 25px, 25px, 150px, 121px, 'sprite.png', 'shopping-cart-a', );
$shopping-cart-name: 'shopping-cart';
$shopping-cart-x: 62px;
$shopping-cart-y: 0px;
$shopping-cart-offset-x: -62px;
$shopping-cart-offset-y: 0px;
$shopping-cart-width: 25px;
$shopping-cart-height: 25px;
$shopping-cart-total-width: 150px;
$shopping-cart-total-height: 121px;
$shopping-cart-image: 'sprite.png';
$shopping-cart: (62px, 0px, -62px, 0px, 25px, 25px, 150px, 121px, 'sprite.png', 'shopping-cart', );
$twitter-name: 'twitter';
$twitter-x: 31px;
$twitter-y: 0px;
$twitter-offset-x: -31px;
$twitter-offset-y: 0px;
$twitter-width: 30px;
$twitter-height: 25px;
$twitter-total-width: 150px;
$twitter-total-height: 121px;
$twitter-image: 'sprite.png';
$twitter: (31px, 0px, -31px, 0px, 30px, 25px, 150px, 121px, 'sprite.png', 'twitter', );
$untitled-a-name: 'untitled-a';
$untitled-a-x: 50px;
$untitled-a-y: 79px;
$untitled-a-offset-x: -50px;
$untitled-a-offset-y: -79px;
$untitled-a-width: 24px;
$untitled-a-height: 20px;
$untitled-a-total-width: 150px;
$untitled-a-total-height: 121px;
$untitled-a-image: 'sprite.png';
$untitled-a: (50px, 79px, -50px, -79px, 24px, 20px, 150px, 121px, 'sprite.png', 'untitled-a', );
$untitled-name: 'untitled';
$untitled-x: 25px;
$untitled-y: 79px;
$untitled-offset-x: -25px;
$untitled-offset-y: -79px;
$untitled-width: 24px;
$untitled-height: 20px;
$untitled-total-width: 150px;
$untitled-total-height: 121px;
$untitled-image: 'sprite.png';
$untitled: (25px, 79px, -25px, -79px, 24px, 20px, 150px, 121px, 'sprite.png', 'untitled', );
$vkontakte-name: 'vkontakte';
$vkontakte-x: 31px;
$vkontakte-y: 31px;
$vkontakte-offset-x: -31px;
$vkontakte-offset-y: -31px;
$vkontakte-width: 30px;
$vkontakte-height: 18px;
$vkontakte-total-width: 150px;
$vkontakte-total-height: 121px;
$vkontakte-image: 'sprite.png';
$vkontakte: (31px, 31px, -31px, -31px, 30px, 18px, 150px, 121px, 'sprite.png', 'vkontakte', );
$youtube-name: 'youtube';
$youtube-x: 0px;
$youtube-y: 31px;
$youtube-offset-x: 0px;
$youtube-offset-y: -31px;
$youtube-width: 30px;
$youtube-height: 22px;
$youtube-total-width: 150px;
$youtube-total-height: 121px;
$youtube-image: 'sprite.png';
$youtube: (0px, 31px, 0px, -31px, 30px, 22px, 150px, 121px, 'sprite.png', 'youtube', );
$spritesheet-width: 150px;
$spritesheet-height: 121px;
$spritesheet-image: 'sprite.png';
$spritesheet-sprites: ($arrow-down, $arrow-left, $arrow-right-small-black, $arrow-right-small-gray, $arrow-right-small-red, $arrow-right, $arrow-top, $block-a, $block, $facebook, $heart-a, $heart, $insta, $list-a, $list, $mail, $menu-mobile-a, $menu-mobile, $ok, $phone, $search-a, $search, $shopping-cart-a, $shopping-cart, $twitter, $untitled-a, $untitled, $vkontakte, $youtube, );
$spritesheet: (150px, 121px, 'sprite.png', $spritesheet-sprites, );

// The provided mixins are intended to be used with the array-like variables
//
// .icon-home {
//   @include sprite-width($icon-home);
// }
//
// .icon-email {
//   @include sprite($icon-email);
// }
//
// Example usage in HTML:
//
// `display: block` sprite:
// <div class="icon-home"></div>
//
// To change `display` (e.g. `display: inline-block;`), we suggest using a common CSS class:
//
// // CSS
// .icon {
//   display: inline-block;
// }
//
// // HTML
// <i class="icon icon-home"></i>
@mixin sprite-width($sprite) {
  width: nth($sprite, 5);
}

@mixin sprite-height($sprite) {
  height: nth($sprite, 6);
}

@mixin sprite-position($sprite) {
  $sprite-offset-x: nth($sprite, 3);
  $sprite-offset-y: nth($sprite, 4);
  background-position: $sprite-offset-x  $sprite-offset-y;
}

@mixin sprite-image($sprite) {
  $sprite-image: nth($sprite, 9);
  background-image: url(#{$sprite-image});
}

@mixin sprite($sprite) {
  @include sprite-image($sprite);
  @include sprite-position($sprite);
  @include sprite-width($sprite);
  @include sprite-height($sprite);
}

// The `sprites` mixin generates identical output to the CSS template
//   but can be overridden inside of SCSS
//
// @include sprites($spritesheet-sprites);
@mixin sprites($sprites) {
  @each $sprite in $sprites {
    $sprite-name: nth($sprite, 10);
    .#{$sprite-name} {
      @include sprite($sprite);
    }
  }
}
