.title {
    span,
    h1,
    h2 {
			font-family: "Roboto";
			font-weight: bold;
        display: block;
        font-size: 1.3rem;
    }
}
.index-title {
	font-size: 1.5rem;
}
h1, h2, h3, h4, h5, h5 {
	color:#d21d1d;
}