.kamin-list {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    flex-wrap: wrap;
    margin-bottom: 40px;
    .kamin-item {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        background-color: #fff;
        margin: 10px;
				width: 360px;
				@include desktop {
					margin: 5px;
					width: 340px;
				}
        &:hover {
            z-index: 3;
            box-shadow: 0px 0px 20px 0px $gray;
        }
        a.kamin-item__img {
						height: 230px;
						display: flex;
						justify-content: center;
						align-items: center;
						overflow: hidden;
						@include desktop {
							height: 218px;
						}
            img {
								// width: 100%;
								height: 95%;
            }
            &:hover {
                opacity: 0.7;
            }
        }
        .kamin-item__text {
            padding: 0 10px;
            span {
                display: block;
                font-family: "Roboto";
								font-weight: 500;
                font-size: 1.2rem;
                color:$black;
								margin-top: 10px;
								margin-bottom: 10px;
            }
            p {
                margin: 0px;
                font-size: 0.9rem;
								color:$gray;
								
								overflow: hidden;
								// height: 87px;
								text-overflow: ellipsis;
								&:last-child {
									margin-bottom: 10px;
								}
            }
        }
        .kamin-item__price {
            display: flex;
            justify-content: space-between;
            padding: 0 10px;
            span {
							font-family: "Roboto";
							font-weight: bold;
							font-size: 1.3rem;
							color:$red;
							span {
								font-weight: normal;
							}
            }
            strong {
                font-size: 0.9rem;
                font-weight: normal;
                color:$black;
            }
        }
        .kamin-item__footer {
            display: flex;
            justify-content: flex-start;
            border-top:1px solid $bggray;
            margin-top: 20px;
            .kamin-item__comparison {
                display: flex;
                justify-content: center;
                align-items: center;
                width: 60px;
                height: 60px;
                border-right:1px solid $bggray;
                &:after {
                    content: "";
                    display: block;
                    @include sprite($untitled);
                }
                &:hover,
                &:active {
                    &:after {
                        @include sprite($untitled-a);
                    }
                }
                // &:hover {
                //     box-shadow: inset 0px 0px 0px 1px $red;
                // }
						}
						button.kamin-item__heart,
            a.kamin-item__heart {
                display: flex;
                justify-content: center;
                align-items: center;
                width: 60px !important;
								height: 60px;
								border: 0;
								background-color: #fff;
								border-right:1px solid $bggray;
								cursor: pointer;
                &:after {
                    content: "";
                    display: block;
                    @include sprite($heart);
                }
                &:hover,
                &:active {
                    &:after {
                        @include sprite($heart-a);
                    }
                }
                // &:hover {
                //     box-shadow: inset 0px 0px 0px 1px $red;
                // }
						}
						button.kamin-item__add-cart,
            a.kamin-item__add-cart {
                color:$red;
                text-align: center;
                padding: 18px 0px;
                white-space: nowrap;
								text-decoration: none;
								background-color: #fff;
								border:0;
								cursor: pointer;
                &:hover {
                    color:#fff;
                    background-color: $red;
                }
            }
        }
    }
}

.js-related-products {
	.owl-stage-outer {
		display: flex;
		// .owl-item {
		// 	display: flex;
		// 	justify-content: center;
		// }
		@include phones {
			justify-content: space-center;
		}
		.kamin-item {
			width: 100%;
			max-width: 360px;
			@include desktop {
				max-width: 340px;
			}
		}
	}
	.owl-item {
		display: flex;
	}
	.owl-stage {
		display: flex;
		align-items: stretch;
	}
	.owl-nav {
		top: -40px;
		right: 10px;
		@include laptop {
			top:-40px;
		}
		.carousel-product-prev, .carousel-product-next {
			width: 40px;
			height: 40px;
		}
	}
}