@mixin desktopL() {
    @media screen and (max-width: 1439px) {
        @content;
    }
}
@mixin desktop() {
    @media screen and (max-width: 1199px) {
        @content;
    }
}
@mixin laptop() {
    @media screen and (max-width: 991px) {
        @content;
    }
}
@mixin tablets() {
    @media screen and (max-width: 767px) {
        @content;
    }
}
@mixin phones() {
    @media screen and (max-width: 575px) {
        @content;
    }
}
@mixin phonesm() {
    @media screen and (max-width: 480px) {
        @content;
    }
}
@mixin phonesms() {
    @media screen and (max-width: 420px) {
        @content;
    }
}
@mixin phoness() {
    @media screen and (max-width: 320px) {
        @content;
    }
}
