.news__list {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    flex-wrap: wrap;
    .news-item {
        width: 49%;
        background-color: #fff;
        padding: 10px 20px;
        margin-bottom: 10px;
        @include phones {
            width: 99%;
        }
        &:hover {
            box-shadow: 0px 0px 10px 0px $gray;
        }
        .news__title {
            a {
                text-decoration: none;
                h3 {
                    font-size: 1.2rem;
                    color:$black;
                    margin: 0;
                    margin-bottom: 5px;
                    &:hover {
                        color:$red;
                    }
                }
            }
        }
        .news__date {
            color:$gray;
            margin-bottom: 5px;
        }
        .news__description {
            display: flex;
            align-items: center;
            justify-content: start;
            a.news__img {
							display: block;
							width: 30%;
							// height: 150px;
							overflow: hidden;
							margin-right: 20px;
							@include laptop {
								width: 50%;
							}
							@include phones {
								width: 70%;
							}
							img {
									width: 100%;
									transition: all 0.5s ease-out;
									&:hover {
											transform: scale(1.1);
									}
							}
							span {
									color:$red;
									&:after {
											content: "";
											display: inline-block;
											@include sprite($arrow-right-small-red);
									}
							}
						}
						p {
							width: 70%;
							@include laptop {
								margin-top: 10px;
								width: 100%;
							}
						}
            @include laptop {
                flex-direction: column;
            }
        }
    }
}

.news {
    .pagination {
        .page-item {
            .page-link {
                padding: 20px 22px;
                border-color: $bggray;
                border-radius: 0;
                color:$gray;
                &:hover,
                &:active {
                    background-color: #fff;
                    color:$red;
                }
                &:active {
                    font-weight: bold;
                }
            }
        }
    }
}