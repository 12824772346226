.city {
    font-size: 0.8rem;
    a {
        color: $red;
        // text-decoration: underline;
				// text-decoration-style: dotted;
				text-decoration: none;
        cursor: default;
    }
    &:after {
        content: "";
        display: inline-block;
        // @include sprite($arrow-down);
        margin-left: 10px;
    }
    @include phones {
        margin: 3px 0;
    }
}

.bg-white {
    margin: 0;
}

.menu-main__wrap {
    border-top:1px solid $bggray;
}

.stickytop {
    position:fixed;
    top:0;
    left:0;
    right: 0;
    box-shadow: 0px 0px 2px 2px rgba(0, 0, 0, 0.1);
    animation: fade-in 0.3s linear;
    z-index: 100;
}
.logo {
	margin-left: 10px;
	margin-right: 10px;
	a {
		display: flex;
		align-items: center;
		text-decoration: none;
		p {
			line-height: 20px;
			font-style: italic;
			display: block;
			color:$gray;
			margin: 0;
			margin-left: 20px;
		}
	}
	@include desktop {
		a {
			p {
				line-height: 18px;
			}
		}
	}
	@include phones {
			margin: 5px;
			a {
					display: block;
					text-align: center;
					p {
						display: none;
						line-height: 16px;
						margin-top: 10px;
					}
			}
	}
}
.time,
header .phone-head {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    @include phones {
        margin: 5px;
        text-align: center;
    }
}

.time {
    display: flex;
    justify-content: start;
    font-size: 0.9rem;
		color:$gray;
		// margin: 0 10px;
		// margin-left: 40px;
		ul {
			margin: 0 auto;
			li {
				white-space: nowrap;
			}
		}
    span {
        color:$black;
    }
    @include laptop {
				justify-content: center;
    }
    @include phones {
        margin-left: 0;
        display:none;
    }
}
header .phone-head {
    a {
        color: $black;
        font-size: 1.4rem;
        text-decoration: none;
        margin-right: 35px;
    }
    &:before {
        content: "";
        display: inline-block;
        @include sprite($phone);
				margin-right: 10px;
				margin-left: 10px;
    }
}

.search,
.heart,
.cart,
.menu-mobile {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 70px;
    height: 70px;
    border-left:1px solid $bggray;
    &:after {
        content:"";
        display: flex;
    }
    // &:hover {
        // box-shadow: inset 0px 0px 0px 1px $red;
    // }
    @include laptop {
        border:1px solid $bggray;
        border-bottom: none;
    }
    @include phones {
        width: 45px;
				height: 45px;
				color: #c2c2c2;
				text-decoration: none;
				&:hover {
					color: $red;
					text-decoration: none;
				}
    }
}
#mobileMenuButton {
	display: flex;
	flex-direction: row-reverse;
	justify-content: space-around;
	width: 100px;
	color: #c2c2c2;
	&:hover {
		color: $red;
		text-decoration: none;
	}
}
.menu-mobile {
    &:after {
        @include sprite($menu-mobile);
    }
    &:hover,
    &:active {
        &:after {
            @include sprite($menu-mobile-a);
        }
    }
}
.search {
    &:after {
        @include sprite($search);
    }
    &:hover,
    &:active {
        &:after {
            @include sprite($search-a);
        }
    }
}
.heart {
    &:after {
        @include sprite($heart);
    }
    &:hover,
    &:active {
        &:after {
            @include sprite($heart-a);
        }
    }
}
.cart {
    &:after {
        @include sprite($shopping-cart);
    }
    &:hover {
        &:after {
            @include sprite($shopping-cart-a);
        }
    }
    span {
        position: absolute;
        top: 20px;
        right: 15px;
        display: block;
        width: 17px;
        height: 17px;
        background-color: $red;
        color:#fff;
        border:3px solid #fff;
        border-radius: 50%;
        font-size: 10px;
        line-height: 12px;
				text-align: center;
				@include phones {
					top: 7px;
					right: 3px;
				}
    }
}

.search-group {
	display: flex;
	flex-direction: row;
	align-content: center;
	width: 70px;
	transition: width 0.7s ease-in-out;
	@include tablets {
		display: none;
	}
	.form-control {
		border-radius:0;
		border-top:none;
		border-right:0;
		border-bottom:none;
		padding: 0 10px;
		color:$grayl;
		border-left-color: $bggray;
		margin: auto;
		&:focus {
			box-shadow: none;
		}
		@include desktop {
			// border-top:1px solid $bggray;
		}
	}
	.search {
		background-color: #fff;
		width: 70px;
        height: 70px;
		margin: 0;
		margin-left: -70px;
		z-index: 1;
		cursor: pointer;
		@include tablets {
			display: none;
		}
      .submit {
        background-color: transparent;
        border: none;
        display: none;
      }
	}
	input:active, input:hover, input:focus {
			outline: 0;
			outline-offset: 0;
	}
}

.search-group-width {
	width: 300px;
	transition: width 0.7s ease-in-out;
	@include desktop {
		width: 250px;
	}
	@include desktop {
		position: absolute;
		top: -35px;
		right: 0;
		width: 250px;
	}
  .search {
    position: relative;
    .submit {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      width: 100%;
      display: block;
      //background-color: red;
      cursor: pointer;
      font-size: 1px;
      color: transparent;
    }
  }
}
